<template>
  <b-card
    class="earnings-card"
  >
    <b-row>
      <!-- <b-col cols="6">
        <b-card-title class="mb-1">
          Earnings
        </b-card-title>
        <div class="font-small-2">
          This Month
        </div>
        <h5 class="mb-1">
          $4055.56
        </h5>
        <b-card-text class="text-muted font-small-2">
          <span class="font-weight-bolder">68.2%</span><span> more earnings than last month.</span>
        </b-card-text>
      </b-col> -->

      <b-col cols="12">
    <div id="chart">
      <vue-apex-charts height="350" :options="chartOptions" :series="series"/>
    </div>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component';

const $earningsStrokeColor1 = '#6c5dd345'
const $earningsStrokeColor2 = '#f65160b8'
const $earningsStrokeColor3 = '#2b78eba8'
export default {
  components: {
    VueApexCharts,
    flatPickr
  },
  data() {
    return {
      
      series: [{
            name: 'Net Profit',
            data: [44, 55, 57, 56, 61, 58],
            color:'#3F8CFF'
          }, {
            name: 'Revenue',
            data: [76, 85, 101, 98, 87, 105],
            color:'#F65160'
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '70%',
              },
            },
            dataLabels: {
              enabled: false
            },
            title: {
              text: 'Total Email Sent This Month',
              align: 'left'
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            },
            yaxis: {
              title: {
                text: '$ (thousands)'
              }
            },
            fill: {
              opacity: 1
            }, legend: {
              horizontalAlign: 'right',
              position:'top'
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "$ " + val + " thousands"
                }
              }
            }
          },
          
    }
  },
}
</script>
<style>
</style>