<template>
    <b-card
    class="earnings-card monetized-card-width m-0"
    >
      <b-row>
        <b-col cols="12">
          <h5 class="text-black" style="font-size:14px;white-space:nowrap;font-family: Helvetica, Arial, sans-serif;font-weight: 800;">Monetized Coupons Stores</h5>
          <vue-apex-charts
            height="400"
            :options="earningsChart.chartOptions"
            :series="earningsChart.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BCardTitle, BCardText,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
  const $earningsStrokeColor1 = '#6c5dd345'
  const $earningsStrokeColor2 = '#f65160b8'
  const $earningsStrokeColor3 = '#2b78eba8'
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BCardTitle,
      BCardText,
      VueApexCharts,
    },
      props:['data'],
    data(props) {
      return {
        earningsChart: {
          series: [props.data.monetized_code,props.data.nonmonetized_code],
          chartOptions: {
            chart: {
              type: 'donut',
              toolbar: {
                show: true,

              },
            },
            // title: {
            //   text: 'Monetized Coupons Stores',
            //   align: 'left'
            // },
            dataLabels: {
              enabled: false,
            },
            legend: { show: true ,position:'bottom'},
            comparedResult: [2, -3, 8],
            labels: ['Monetized Code', 'Non-Monetized Code'],
            stroke: { width: 0 },
            colors: [$earningsStrokeColor2, $earningsStrokeColor3],
            plotOptions: {
              pie: {
                startAngle: 0,
                donut: {
                  labels: {
                    show: true,
                    name: {
                      offsetY: 15,
                    },
                    value: {
                      offsetY: -15,
                      formatter(val) {
                        // eslint-disable-next-line radix
                        return `${parseInt(val)}`
                      },
                    },
                    total: {
                      show: true,
                      offsetY: 15,
                      label: 'Total',
                      formatter() {
                        return props.data.monetized_code+props.data.nonmonetized_code
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }
    },
  }
  </script>
  <style>
</style>