<template>
    <b-card  style="height:10.5cm"
    class="earnings-card monetized-card-width m-0"
    >
      <b-row>
        <b-col cols="12">
          <vue-apex-charts
            height="305"
            :options="earningsChart.chartOptions"
            :series="earningsChart.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BCardTitle, BCardText,
  } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  
  const $earningsStrokeColor1 = '#6c5dd345'
  const $earningsStrokeColor2 = '#f65160b8'
  const $earningsStrokeColor3 = '#2b78eba8'
  const $earningsStrokeColor4 = '#00e272'
  const $earningsStrokeColor5 = '#6b8abc'
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BCardTitle,
      BCardText,
      VueApexCharts,
    },
    props:['data','total'],
    data(props) {
      return {
        earningsChart: {
          series: [parseInt(props.data.monetized_coupon),parseInt(props.data.monetized_link),parseInt(props.data.nonmonetized_coupon),parseInt(props.data.nonmonetized_link),parseInt(props.data.paid_advertisers)],
          // series: [218,234,4234,4356,3456,234],
          chartOptions: {
            chart: {   
                align:'left',
              type: 'donut',
              toolbar: {
                show: true,

              },
            },
            // subtitle: {
            //   text: 'Customer that Buy our Product',
            //   align: 'left'
            // },
            dataLabels: {
              enabled: false,
            },
            title: {
              text: 'Coupons Graph',
              align: 'left'
            },
            legend: { show: true },
            // comparedResult: [2, -3, 8],
            labels: ['Monetized Coupons', 'Monetized Links', 'Non-Monetized Coupons', 'Non-Monetized Links', 'Paid Advertisers'],
            stroke: { width: 0 },
            colors: [$earningsStrokeColor1, $earningsStrokeColor2, $earningsStrokeColor3,$earningsStrokeColor4,$earningsStrokeColor5],
            grid: {
            //   padding: {
            //     right: -20,
            //     bottom: -8,
            //     left: -20,
            //   },
            },
            plotOptions: {
              pie: {
                startAngle: 0,
                donut: {
                  labels: {
                    show: true,
                    name: {
                      offsetY: 15,
                    },
                    value: {
                      offsetY: -15,
                      formatter(val) {
                        // eslint-disable-next-line radix
                        return `${parseInt(val)}`
                      },
                    },
                    total: {
                      show: true,
                      offsetY: 15,
                      label: 'Total',
                      formatter() {
                        return props.total
                      },
                    },
                  },
                },
              },
            },
          },
        },
      }
    },
  }
  </script>
  <style>
  /* .apexcharts-legend.apexcharts-align-center.position-right{
    margin-right:80px;
    margin-top:30px
  } */
</style>