<template>
    <b-card
      class="earnings-card"
    >
      <b-row>
        <!-- <b-col cols="6">
          <b-card-title class="mb-1">
            Earnings
          </b-card-title>
          <div class="font-small-2">
            This Month
          </div>
          <h5 class="mb-1">
            $4055.56
          </h5>
          <b-card-text class="text-muted font-small-2">
            <span class="font-weight-bolder">68.2%</span><span> more earnings than last month.</span>
          </b-card-text>
        </b-col> -->

        <b-col cols="12">
      <div id="chart">
        <vue-apex-charts height="350" :options="chartOptions" :series="series"/>
      </div>

        </b-col>
      </b-row>
    </b-card>
  </template>
  
  <script>
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component';
  
  const $earningsStrokeColor1 = '#6c5dd345'
  const $earningsStrokeColor2 = '#f65160b8'
  const $earningsStrokeColor3 = '#2b78eba8'
  export default {
    components: {
      VueApexCharts,
      flatPickr
    },
    props:['data'],
    data() {
      return {
        date:new Date(),
        series: [],
          
          chartOptions: {
            chart: {
              type: 'area',
              height: 350,
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            
            title: {
              text: 'Total Coupon Clicks Count',
              align: 'left'
            },
            subtitle: {
              text: '',
              align: 'left'
            },
            legend: {
              horizontalAlign: 'left',
              position:'top'
            }
          },
      }
    },
    mounted(){
      let codeMone = []
      let linkMone = []
      this.data.mcc_value.map((i,index) => {
        let obj ={
          x:this.data.dates[index],
          y:i
        }
        codeMone.push(obj)
      }) 
       this.data.nmcc_value.map((i,index) => {
        let obj ={
          x:this.data.dates[index],
          y:i
        }
        linkMone.push(obj)
      })
      this.series=[{
        name:'Engaged Audience',
        data:codeMone,
            color:'#FF5733'
      },
    {
      name:'Page Impressions',
        data:linkMone,
            color:'#3F8CFF'
    }]
      this.$forceUpdate();
        }
  }
  </script>
  <style>
</style>