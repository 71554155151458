<template>
  <div>

    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" @refetch-data="refetchData" />

    <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
      :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" /> -->

    <b-card no-body class="mb-0">
        <div class="w-100 d-flex justify-content-between">
          <h3  style="font-family: Helvetica, Arial, sans-serif;font-weight: 800;" class="p-1">Product Sales{{ search }}</h3>
    <button  v-if="!isLoading && GridData.length" type="button" class="btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        Page Length
     <v-select style="width:100px" v-model="pageLength" @input="pageLengthChanged" :options="[10,50,100,1000]" :clearable="false">
     </v-select>
      </div>
    </button>
  </div>
        <div v-if="isLoading" style="height:200px" class="d-flex justify-content-center align-items-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        
        <b-table v-else ref="refUserListTable" :loading="isLoading"
         class="position-relative" :items="GridData" responsive
          :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc">

          <template #cell(id)="data">
            <span class="text-primary font-weight-bold" style="font-weight:400;font-size:15px">
              {{ (data.index + 1) }}
            </span>
          </template>
          <template #cell(f_name)="data">
            <div style="font-size:15px ;font-weight:600;font-family: 'Public Sans', sans-serif;">
              <div>
                <span class="mr-1">
                  <b-avatar size="32" :src="data.item.avatar" :text="data.item.f_name.substring(0, 1)" />
                </span>
                {{ data.item.f_name }}
              </div>
            </div>
          </template>
          <template #cell(trainer)="data">
            <div class="d-flex w-100 align-items-center" v-if="data.item.trainer">
              <div class="mr-1">
                <b-avatar size="32" :src="data.item.trainer.profilephoto"
                  :text="data.item.trainer.f_name.substring(0, 1)" />
              </div>
              <div>
                <p class="m-0" style="font-size:15px ;font-weight:600;font-family: 'Public Sans', sans-serif;">{{
                  data.item && data.item.trainer ? data.item.trainer.f_name : '' }}
                </p>
                <small style="font-size:13px ;font-weight:400;font-family: 'Public Sans', sans-serif;">{{
                  data.item.trainer && data.item.trainer.specialized ? data.item.trainer.specialized.name : '' }}</small>
              </div>
            </div>
          </template>
          <template #cell(goal)="data">
            <div class="d-flex w-100 align-items-center">
              <p style="font-size:15px ;font-weight:400;font-family: 'Public Sans', sans-serif;">{{
                data.item.goal ? data.item.goal.name : "" }}</p>
            </div>
          </template>
          <template #cell(phone)="data">
            <div class="d-flex w-100 align-items-center">
              <p style="font-size:15px ;font-weight:400;font-family: 'Public Sans', sans-serif;">{{ data.item.phone }}</p>
            </div>
          </template>
          <template #cell(Progress)="data">
            <div class="d-flex align-items-center">
              <span style="font-size:12px ;font-weight:400;font-family: 'Public Sans', sans-serif;">{{ data.item.process
              }}</span>
              
            </div>
          </template>
          <template #cell(Amount)="data">
              <div class="d-flex align-items-center">
                <span style="font-size:12px ;font-weight:400;font-family: 'Public Sans', sans-serif;">{{ data.item.process
                }}</span>
                
              </div>
            </template>
            <template #cell(NewAmount)="data">
              <div class="d-flex align-items-center">
                <span style="font-size:12px ;font-weight:400;font-family: 'Public Sans', sans-serif;">{{ data.item.process
                }}</span>
                
              </div>
            </template>
          <template #cell(is_active)='data'>
            <div class="ciecleEyeIcon">
            <feather-icon icon="EyeIcon" size="16"   @click="$router.push(`/monetization-view/${data.item.id}`)"
              style="cursor: pointer;margin-right:10px;color:#3F8CFF;margin:0px" />
            </div>
          </template>
        </b-table>
          <!-- Pagination -->
          <div>
  </div>
    </b-card>
      
    <button v-if="!isLoading && GridData.length" type="button" class="w-auto btn slide-btn mt-2">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{parseInt(paginationEnd) > parseInt(paginationLength) ? paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BProgress, BFormGroup, BFormSelect, BForm, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required
} from "@validations";
import _ from 'lodash';
import flatPickr from 'vue-flatpickr-component'


export default {
  components: {
    BFormSelect,
    BInputGroup,
    BCard,
    flatPickr,
    BRow,
    moment,
    required,
    BInputGroupAppend,
    vSelect,
    BCol,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    Date:'',
    required,
    BPagination,
    BSpinner,
  },
  data() {
    return {
      paginationStart: 0,
      pageNo: 1,
      paginationEnd: 10,
      paginationLength: 0,
      pageLength:10,
      isLoading : false,
      tableColumns: [
        {
          key: 'id', label: '', sortable: true
        },
          { key: 'name_en', label: 'Name', sortable: true },
        { key: 'total_code_monetized', label: 'Total Code Monetized', sortable: true },
        { key: 'total_link_monetized', label: 'Total Link Monetized', sortable: true },
        { key: 'total_monetized', label: 'Total Monetized', sortable: true },
        { key: 'total_non_monetized', label: 'Total Non Monetized', sortable: true },
        { key: 'copy_count', label: 'Copy Count', sortable: true },
        { key: 'is_active', label: 'Action' },
      ],
      searchTerm: { name: 'Client', value: 'customer' },
      fullname: '',
      height: '',
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      baseURL: process.env.VUE_APP_BASE_URL,
      weight: '',
      isLoading: false,
      email: '',
      contact: '',
      pincode: '',
      language: null,
      langOption: [],
      password: "",
      confirm_password: "",
      value: 60,
      isSortDirDesc: true,
      sortBy: '',
      passwordMismatch: false,
      AddNewUser: false,
      gender: '',
      GridData:[],
      goal: '',
      loader: true,
      AllTrainer:[],
      goalOption: [],
      passwordIcon: 'password',
      confirmpasswordIcon: 'password',
      genderOption: [],
      country: '',
      contryOption: [],
      plans: '',
      plansOption: [],
      ifEdit: false,
      id: 0,
      assignTrainer: false,
      startdate:'',
      enddate:'',
      per_page: 10,
      searchOptions: [
        { name: 'Client', value: 'customer' },
        { name: 'Goal', value: 'goal' },
        { name: 'Trainer', value: 'trainer' },
      ]
    }
  },
  computed:{
    search() {
      let search = this.$store.state.searchData
      this.getSearch(search);
    },
  },
  mounted(){
    this.isLoading = true
       this.$http.post(`${this.baseURL}/client/grid-data`,{pageno:1,pageLength:this.pageLength}).then((json) => {
        this.GridData = json.data.data
        this.paginationLength = json.data.totaldata
        this.$emit('getGridData' ,this.GridData)
        this.isLoading = false
      })
    },
    methods:{
      getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/client/search-grid-data`, {pageno:1,pageLength:this.pageLength,search:search},
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          console.log(json, 'res')
        this.GridData = json.data.data
        this.paginationLength = json.data.totaldata
        this.isLoading = false
        this.$emit('getGridData' ,this.GridData)
        })
      // }
    }, 1000),
      pageLengthChanged(){
        let data = {
          pageno:1,
          pageLength:this.pageLength
        }
        console.log(this.pageLength)
        if(this.startdate != ''){
          data = { ...data,
            startdate:this.startdate,
          enddate:this.enddate
        }
        }
        this.isLoading = true
        if(this.$store.state.searchData){
          this.getSearch()
        }else{
        this.$http.post(`${this.baseURL}/client/grid-data`,data).then((json) => {
        this.GridData = json.data.data
        this.paginationLength = parseInt(json.data.totaldata)
        this.isLoading = false
        this.paginationStart = 0
        this.pageNo= 1
        this.paginationEnd = parseInt(this.pageLength)
        console.log(this.paginationEnd)
      })
     }
      },
      childMethod(date){
        this.startdate = date[0]
        this.enddate = date[1]
        this.pageLengthChanged()
      },
    pagechange() {
      if (this.pageNo > (this.paginationLength /  this.pageLength)) {
        this.pageNo = parseInt(this.paginationLength /  this.pageLength)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * this.pageLength) - this.pageLength
      this.paginationEnd = (this.pageNo * this.pageLength)
        this.normalPaginationHandelClick()
    },
    normalPaginationHandelClick: _.debounce(function () {
    this.isLoading = true
      this.$http.post(`${this.baseURL}/client/grid-data`, { pageno: this.pageNo,pageLength:this.pageLength }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.GridData = res.data.data
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.isLoading = false
      })
    }, 1000),
    searchPaginationHandelClick: _.debounce(function () {
    this.isLoading = true
      this.$http.post(`${this.baseURL}/client/grid-data`, { pageno: this.pageNo,pageLength:this.pageLength }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.GridData = res.data.data
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.isLoading = false
      })
    }, 1000),
    
    paginationHandelClick(value) {
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - this.pageLength
          this.paginationEnd = this.paginationEnd - this.pageLength
        if(this.$store.state.searchData){
          this.searchPaginationHandelClick(value)
        }else{
          this.normalPaginationHandelClick(value)
        }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + this.pageLength
          this.paginationEnd = this.paginationEnd + this.pageLength
        if(this.$store.state.searchData){
          this.searchPaginationHandelClick(value)
        }else{
          this.normalPaginationHandelClick(value)
        }
        }
      }
    }
    }
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  min-width: 250px;
  float: right;
}

.selectTag{
  font-family:none !important;
}
.slide-btn1 {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: left;
}
.ciecleEyeIcon{
  background-color: #3f8cff24;
  border-radius: 75%;
    /* display: flex; */
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>