<template>
  <div class="container">
    <div class="sec1 d-flex justify-content-between pb-1">
    <h1 class="text-black">Monetization{{app}}</h1>
      <div>
        <!-- <button class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
          </button> -->

        <button id="popover-button-bottom" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1">
          <div>
            <img src="../../../assets/images/client/filter.svg" alt="Snow" />
          </div>
        </button>

        <b-popover ref="popover" target="popover-button-bottom" triggers="hover" placement="Left"
          class="custom-popover pop-btn">
        <flat-pickr v-model="date" :config="{
              static:true,
              dateFormat: 'd/m/Y',
              mode: 'range',
              inline	: true,
            }"
            @input="clickFilterChange()"
            />
        </b-popover>  

        <button type="button" v-b-tooltip.hover.v-primary  title="Export CSV"
          variant="outline-primary" class="btn notofication-btn mr-1" @click="handleExport">
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button></div>
</div>
    <b-row>
      <b-col v-if="Object.keys(couponsTrend).length > 0" sm="6" md="6" lg="6">
      <coupon-overview :data="couponsTrend" :total="couponsTrendSum"/>
    </b-col>
    <b-col v-if="Object.keys(storesTrend).length > 0" sm="3" md="3" lg="3">
      <customers-1 :data="storesTrend"/>
  </b-col>
    <b-col v-if="Object.keys(storesTrend).length > 0" sm="3" md="3" lg="3">
      <customers-2 :data="storesTrend"/>
  </b-col>
    <b-col v-if="couponsMonetized.dates" sm="12" md="6" lg="6">
      <coupon-monetized :data="couponsMonetized"/>
      </b-col>
    <b-col v-if="linkMonetized.dates" sm="12" md="6" lg="6">
      <link-monetized :data="linkMonetized"/>
      </b-col>
    <b-col v-if="monetized_stores.dates" sm="12" md="6" lg="6">
      <monetized-stores :data="monetized_stores"/>
  </b-col>
    <b-col v-if="monetized_stores_percentage.dates" sm="12" md="6" lg="6">
      <monetized-stores-percentage :data="monetized_stores_percentage"/>
  </b-col>
    <b-col v-if="daily_coupons_clicks_count.dates" sm="12" md="6" lg="6">
      <daily-coupon :data="daily_coupons_clicks_count"/>
  </b-col>
    <b-col v-if="total_coupon_clicks_count.dates" sm="12" md="6" lg="6">
      <total-coupon :data="total_coupon_clicks_count"/>
  </b-col>
    <!-- <b-col sm="12" md="6" lg="6">
      <customers-5/>
  </b-col>
    <b-col sm="12" md="6" lg="6">
      <customers-5/>
  </b-col> -->

    <b-col sm="12" md="12" lg="12">
        <grid ref="childRef" @getGridData="getGridData"/>
    </b-col>
    </b-row>
  </div>
</template>
<script>
import CouponOverview from "./CouponOverview.vue";
import Customers1 from "./Customers1.vue";
import Customers2 from "./Customers2.vue";
import Customers4 from "./Customers4.vue";
import Customers5 from "./Customers5.vue";
import DailyCoupon from './DailyCoupon.vue';
import TotalCoupon from './TotalCoupon.vue';
import CouponMonetized from './CouponMonetized.vue';
import LinkMonetized from './LinkMonetized.vue';

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Grid from "./GridClientAndStores.vue";
import MonetizedStores from './MonetizedStores.vue';
import MonetizedStoresPercentage from './MonetizedStoresPercentage.vue';
import { codeListGroupAction } from '@/views/components/list-group/code';


export default{
  components:{
    CouponOverview,
    Customers1,
    Customers2,
    Customers4,
    Customers5,
    Grid,
    MonetizedStores,
    MonetizedStoresPercentage,
    DailyCoupon,
    TotalCoupon,
    CouponMonetized,
    LinkMonetized,flatPickr
  },
  mounted(){
    this.getGraphdata()
  },
  data(){
    return{
      baseURL: process.env.VUE_APP_BASE_URL,
      daily_coupons_clicks_count:[],
      monetized_stores:[],
      monetized_stores_percentage:[],
      total_coupon_clicks_count:[],
      couponsTrend:{},
      couponsTrendSum:0,
      storesTrend:{},
      couponsMonetized:{},
      linkMonetized:{},
      date:'',
      gridData:[]
  }
  },
  computed: {
    app(){
    if(this.$store.state.application){
      this.clickFilterChange()
      this.date = ''
      }
    }
  },
  methods:{
    handleExport(){
      var aoa = [
                ['SR.NO', 'NAME', 'TOTAL CODE MONETIZED', 'TOTAL LINK MONETIZED', 'TOTAL MONETIZED', 'TOTAL NON MONETIZED', 'COPY COUNT'],
                [],
            ];

            this.gridData.map((item,index)=>{
              aoa.push([index+1,item.name_en,item.total_code_monetized,item.total_link_monetized,item.total_monetized,item.total_non_monetized,item.copy_count])
            })

            var ws = XLSX.utils.aoa_to_sheet(aoa);
            var html_string = XLSX.utils.sheet_to_html(ws, { id: "data-table", editable: true });
            document.getElementById("container").innerHTML = html_string;
            function doit(type, fn) {
                var elt = document.getElementById('data-table');
                var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
                XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
            }
            doit('xlsx', 'GridData' + '.xlsx');
    },
    getGridData(data){
      this.gridData= data
    },
    async getGraphdata(){
       this.$http.post(`${this.baseURL}/client/get-charts`).then((json) => {
        this.monetized_stores = json.data.monetized_stores
        this.monetized_stores_percentage = json.data.monetized_stores_percentage
        this.daily_coupons_clicks_count = json.data.daily_coupons_clicks_count
        this.total_coupon_clicks_count = json.data.total_coupon_clicks_count
      })
       this.$http.post(`${this.baseURL}/client/get-pie-charts`).then((json) => {
        this.couponsTrend = json.data.couponsTrend
        this.storesTrend = json.data.storesTrend
        this.couponsTrendSum = this.sum(this.couponsTrend)
      })
        this.$http.post(`${this.baseURL}/client/get-monetized-graphs`).then((json) => {
        this.couponsMonetized = json.data.couponsMonetized
        this.linkMonetized = json.data.linkMonetized
      })
    },
    sum(obj){
        let sum = 0;
      for (const key in obj) {
          // Check if the value is numeric (excluding strings)
          if (!isNaN(obj[key]) && typeof obj[key] !== "string") {
            sum += Number(obj[key]);
          }
        }
        return sum
    },
    clickFilterChange: _.debounce(function () {
      if(this.date == ''){
        this.getGraphdata()
      }
      let date = this.date.split(' to ')
      console.log(date)
      console.log(this.$refs.popover)
      if(date.length > 1){
      this.monetized_stores = []
      this.monetized_stores_percentage = []
      this.daily_coupons_clicks_count = []
      this.total_coupon_clicks_count = []
      this.couponsTrend = {}
      this.storesTrend ={}
      this.couponsTrendSum = {}
      this.couponsMonetized = {}
      this.linkMonetized = {}
      this.$refs.childRef.childMethod(date);
        this.$http.post(`${this.baseURL}/client/get-charts`,{startdate:date[0],enddate:date[1]}).then((json) => {
        this.monetized_stores = json.data.monetized_stores
        this.monetized_stores_percentage = json.data.monetized_stores_percentage
        this.daily_coupons_clicks_count = json.data.daily_coupons_clicks_count
        this.total_coupon_clicks_count = json.data.total_coupon_clicks_count
        this.$forceUpdate()
      })
       this.$http.post(`${this.baseURL}/client/get-pie-charts`,{startdate:date[0],enddate:date[1]}).then((json) => {
        this.couponsTrend = json.data.couponsTrend
        this.storesTrend = json.data.storesTrend
        this.couponsTrendSum = this.sum(this.couponsTrend)
        this.$forceUpdate()
      })
        this.$http.post(`${this.baseURL}/client/get-monetized-graphs`,{startdate:date[0],enddate:date[1]}).then((json) => {
        this.couponsMonetized = json.data.couponsMonetized
        this.linkMonetized = json.data.linkMonetized
        this.$forceUpdate()
      })
      }
    })
  }
}
</script>

<style lang="scss">  
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.popup-edge{
position: absolute;
width:50px;
height: 50px;
background-color: #ffffff;
right:0;
top:13px;
transform: rotate(45deg);
}
.popover-manual{
  position: absolute;
    right: 6.5cm !important;
    background-color: white;
    border-radius: 26px;
    padding: 5px 15px;
    left: auto;
    top:100px !important;
    z-index: 10000;
}
.popover-inner{
  display: flex;
  z-index: 1;
  position: relative;
}
.success-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.danger-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #c90a0a;
  border: 1px solid #c90a0a;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}

.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  min-width: 250px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}


.modal-body {
  padding: 0px 40px 40px 40px !important
}

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
  height: 77px;
}

textarea.form-control {
  min-height: 150px !important;
}

.popover1-body {
  padding: 0;
}
legend{
  font-weight: 600;
}
.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
</style>
